<template>
	<div>
		<Loader
			v-if="code"
			:absolute-centred="true" />

		<template v-else>
			<Header />

			<Message
				v-if="authError"
				severity="error"
				>{{ authError }}
			</Message>

			<h1 class="h1">{{ $t("pages.login.title") }}</h1>
		</template>
	</div>
</template>

<script setup lang="ts">
	import Header from "~/components/layouts/default/header/Header.vue";

	const { signIn } = useAuth();

	definePageMeta({
		layout: "auth",
		auth: {
			unauthenticatedOnly: true,
			navigateAuthenticatedTo: "/",
		},
	});

	const getAuthCode = () => {
		const callbackUrl = useRequestURL().searchParams.get("callbackUrl");

		if (callbackUrl) {
			return new URL(callbackUrl).searchParams.get("code");
		}

		return "";
	};

	const code = useState("code", () => getAuthCode());
	const authError = ref("");
	const handleError = (message) => {
		code.value = "";
		authError.value = message;
	};

	const logIn = async () => {
		if (!code.value) return false;

		await signIn("credentials", {
			redirect: false,
			code: code.value,
		})
			.then(async (auth) => {
				if (auth?.error) {
					handleError(auth.error || "Ошибка авторизации");
				} else {
					await useRouter().push("/");
				}
			})
			.catch((error) => handleError(error));
	};

	onMounted(() => logIn());
</script>
